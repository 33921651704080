import { template as template_715705517f744e7da41f4206862de1fb } from "@ember/template-compiler";
const FKLabel = template_715705517f744e7da41f4206862de1fb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
