import { template as template_de302d6c4af54095b17acad6f1cd1af7 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_de302d6c4af54095b17acad6f1cd1af7(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
