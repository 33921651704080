import { template as template_2de551b4fca04b28a46ea8e96ca9e326 } from "@ember/template-compiler";
const SidebarSectionMessage = template_2de551b4fca04b28a46ea8e96ca9e326(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
