import { template as template_889182ac812b4eccba927bd9e94d9522 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_889182ac812b4eccba927bd9e94d9522(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
