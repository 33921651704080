import { template as template_2f9dffbddd4842d8a26e6fa2435742de } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_2f9dffbddd4842d8a26e6fa2435742de(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
