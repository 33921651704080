import { template as template_1e098adc04bf45179c40f63a82ea5f5b } from "@ember/template-compiler";
const WelcomeHeader = template_1e098adc04bf45179c40f63a82ea5f5b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
